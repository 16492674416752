<div class="page-container">
    <div class="page-content">
        <div class="title-bar p-3">
            <img width="124" alt="ZEN Master logo" class="align-middle" [src]="appService.whiteLogoUrl" />
        </div>
        <div *ngIf="data$ | async as data" class="content-area">
            <div class="container-fluid h-100">
                <div class="row">
                    <div class="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                        <h1>
                            <zx-status-icon class="d-inline-block" [model]="data.source" size="sm" class="me-1"></zx-status-icon>
                            {{ data.source.name }}
                        </h1>

                        <app-source-thumbnail [source]="data.source" [overlay]="isThumbnailOverlayShow"></app-source-thumbnail>
                        <div class="row mt-3">
                            <div class="col d-flex justify-content-between">
                                <div *ngIf="data.source?.status?.bitrate">
                                    <h5 class="mb-0" translate>BITRATE</h5>
                                    <div>
                                        <span>{{ data.source.status.bitrate | number : "1.0-0" }} kbps</span>
                                    </div>
                                </div>
                                <div *ngIf="data.source?.status?.up_time">
                                    <h5 class="mb-0">{{ "UP_TIME" | translate }} [{{ "HH:mm:ss" | translate }}]</h5>
                                    <div>
                                        <span>{{ data.source.status.up_time | uptime }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col d-flex justify-content-end">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" name="show_overlay" [(ngModel)]="isThumbnailOverlayShow" />
                                    <label class="form-check-label" for="show_overlay">{{ "SHOW_OVERLAY" | translate }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                        <app-bitrates-chart [pids]="data.pids"></app-bitrates-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
