import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, map, tap } from "rxjs";
import { Constants } from "./constants/constants";

type CustomerLogoResultType = { custom_dark_logo_url: string | null; custom_white_logo_url: string | null };

@Injectable({
    providedIn: "root"
})
export class AppService {
    public whiteLogoUrl = "assets/images/ZEN-Master-Logo-white.png";
    public darkLogoUrl = "assets/images/ZEN-Master-Logo.png";

    constructor(private http: HttpClient) {}

    fetchCustomerLogoUrls() {
        const options = {
            headers: {
                dns_prefix: location.hostname === "localhost" ? "zixi" : location.hostname.split(".")[0]
            }
        };

        return this.http
            .get<{ result: CustomerLogoResultType; success: boolean }>(
                Constants.apiUrl + Constants.apiUrls.logo,
                options
            )
            .pipe(
                map(data => data.result),
                tap(urls => {
                    if (urls.custom_dark_logo_url) this.darkLogoUrl = urls.custom_dark_logo_url;
                    if (urls.custom_white_logo_url) this.whiteLogoUrl = urls.custom_white_logo_url;
                })
            );
    }
}
