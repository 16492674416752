import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { SharedLoginService } from "./shared-login.service";
import { AppService } from "src/app/app.service";

@Component({
    selector: "app-shared-login",
    templateUrl: "./shared-login.component.html"
})
export class SharedLoginComponent implements OnInit {
    form: UntypedFormGroup;
    isLoading = false;
    isFormSubmitted = false;

    constructor(
        private sharedLoginService: SharedLoginService,
        private router: Router,
        public appService: AppService
    ) {}

    ngOnInit(): void {
        this.form = new UntypedFormGroup({
            password: new UntypedFormControl("", [Validators.required])
        });
    }

    async onSubmit() {
        this.isFormSubmitted = true;
        if (this.form.invalid) return;
        this.isLoading = true;
        const sharedPageRoute = await this.sharedLoginService.login(this.form.controls.password.value);
        if (sharedPageRoute && this.sharedLoginService.isLoggedIn) {
            this.router.navigate([sharedPageRoute]);
        }
        this.isLoading = false;
    }
}
